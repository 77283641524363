

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
// .mayan-product-list-cards{
//   background-color:white;
//   border:none;
//   border-radius: 10px;
//   width: 100%; 
//   min-height: 40vh !important;
//     max-height: 50vh !important;
//     min-width: 14vw !important;
//     max-width: 17vw !important;
//   // min-height:390px !important;
//   // max-height:390px !important;
//   border: solid;
//   box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
.karupattiraja-product-list-cards{
  background-color:white;
  border:none;
  border-radius: 10px;
  width: 100%; 
  // min-height: 40vh !important;
  //   max-height: 50vh !important;
  //   min-width: 12vw !important;
  //   max-width: 16vw !important;
  // min-height:390px !important;
  // max-height:390px !important;
  border: solid;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);

 }
 .product-list-tilekub{
  height:1.5vw;
  font-family: FontAwesome;
  font-size: 18px!important;
  color: #000!important;
  font-weight: 200!important;
  overflow: hidden!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 2!important;
  -webkit-box-orient: vertical!important;
  text-overflow: ellipsis!important;
  //min-width: max-content;
}
 .product-list-tilevimpro{
  white-space: nowrap;
  font-family: FontAwesome;
  font-size: 20px!important;
  color: #2554C7!important;
  font-weight: 200!important;
  overflow: hidden!important;
  display: -webkit-box!important;
  -webkit-line-clamp: 2!important;
  -webkit-box-orient: vertical!important;
  text-overflow: ellipsis!important;
  //min-width: max-content;
}
.product-list-tile-mayan{
  font-size: 13px;
  color: #3c707c !important;
  font-weight: 600 !important;
 // height: 35px;
  
}
.thumb3 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
   
}
.thumb {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
   
}
.thumb1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 20px
}
.thumb1-deera {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 20px
}
.thumb1-avon {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-left: 20px
}
.thumb1-special {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.div-only-mobile{
  display: none;
  }
  .desk{
   display: block;
    }
.thumbkub {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
 // margin-left: 20px
   
}
.deera-img{
  text-align: center;
}
.special-img{
  text-align: center;
}
.special-img1{
  text-align: center;
}
.deera-atc{
  font-size: small;
  font-weight: bold;
  width: 45%;
  margin-left: 25%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #3c707c !important;
  color: #091214 !important;
  background-color: grey;
}
.avon-atc{
  font-size: small;
  font-weight: bold;
  width: 45%;
  margin-left: 25%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #f33601 !important;
  color: #091214 !important;
  background-color: grey;
}
.avon-atc1{
  font-size: small;
  font-weight: bold;
  width: 45%;
  // margin-left: 25%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #f33601 !important;
  color: #091214 !important;
  background-color: grey;
}
.special-atc{
  font-size: small;
  font-weight: bold;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #5c7fad !important;
  color: #091214 !important;
  background-color: grey;
}
.vistas-atc{
  font-size: small;
  font-weight: bold;
  width: 45%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #022a19 !important;
  color: #091214 !important;
  background-color: grey;
}
.vistas-atc1 {
  border-radius: 17px;
  font-size: small;
  font-weight: 700;
  width: 45%;
  margin-top: 4px;
  background-color: transparent!important;
  border: 2px solid #4e90b2!important;
  color: #091214!important;
  background-color: gray;
}
.special-atc1{
  font-size: small;
  font-weight: bold;
  width: 67%;
  margin-left: 17%;
  margin-top: 4px;
  background-color: transparent !important;
  border: 3px solid #003b85 !important;
  color: #091214 !important;
  background-color: grey;
}
@media only screen and (max-width: 768px) {
.thumbkub{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  //margin-left: 20px
  
  }
 .thumb1{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 20px
  
  }
  .thumb{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px !important
  }
  .thumb1-deera{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px !important
  }
  .thumb1-avon{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-left: 0px !important
  }
  .thumb1-special{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .desk{
    display: none;
    }
   .div-only-mobile{
    display: block;
    }
  .deera-img{
    width: 40%;
  }
  .special-img{
    width: 50%;
  }
  .special-img1{
    width: 80%;
    margin-top: 25px;
  }
  
  .product-list-description-deera{
    width:60%;
  }
  .deera-atc{
    width:60%;
  }
  .review-mob1{
    width: 41%;
    transform: scale(0.8);
    height: 10px;
  }
  .review-mob{
    width: 75%;
    transform: scale(0.8);
    height: 10px;
  }
  .review-mob2{
    transform: scale(0.8);
    height: 10px;
  }
  .review-mob3{
    transform: scale(0.8);
    height: 10px;
    margin-left: -10px;
    width: 35%;
  }
  .deera-container{
    width: 100%;
  }
}
@media  screen and (max-width:391px) {
  .deera-atc{
    width:70%;
  }
  
}
.thumb-content{
  text-align: left;
}
.price-area{
  margin-top: 4%;
}
.cart-buttons{
  float: right;
}
.product-description {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.product-description-bh {
  width: 250px;
}
@media (max-width: 1920px)  {
  .product-list-price1{
    // font-size: 20px ;
    font-size: 18px ;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }
}
.product-list-tile1{
  //color: #0497a6;
  font-size: 14px !important;
  //color: #000 !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  // background-image: linear-gradient(
  //     -225deg,
  //     #231557 0%,
  //     #44107a 29%,
  //     #ff1361 67%,
  //     #fff800 100%
  //   );
  //   background-size: auto auto;
  //   background-clip: border-box;
  //   background-size: 200% auto;
  //   color: #fff;
  //   background-clip: text;
  //   text-fill-color: transparent;
  //   -webkit-background-clip: text;
  //   -webkit-text-fill-color: transparent;
  //   animation: textclip 2s linear infinite;
    display: inline-block;
        font-size: 190px;
}
@media (min-width: 360px) and (max-width: 600px) {
  // .mayan-product-list-cards{
  //   background-color:white;
  //    border:none;
  //    border-radius: 10px;
  //    min-width: 92vw !important;
  //    max-width: 92vw !important;
  //   // width: 92vw;
  //    min-height: 150px !important;
  //    max-height:150px !important;
  //    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19);
  //    display: flex !important;
  //    // position: relative;
  //    // bottom: 20px;
  .karupattiraja-product-list-cards{
    background-color:white;
     border:none;
     border-radius: 10px;
     min-width: 92vw !important;
     max-width: 92vw !important;
    // width: 92vw;
     min-height: 150px !important;
     max-height:150px !important;
     box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2), 4px 4px 4px 4px rgba(0, 0, 0, 0.19);
     display: flex !important;
     // position: relative;
     // bottom: 20px;
     
  //    }
  .product-list-price1{
    // font-size: 20px ;
    font-size: 14px ;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }
}






}

.product-list-tile-special{
  font-size: 16px !important;
  color: #0095da !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}
.avon-foot-img{
  padding:8px;
  object-fit: contain;
  height: 140px;
  width: 185px;
}
.product-list-tile-avon{
  font-size: 16px !important;
  color: #c50 !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}
.product-list-tile-vistas{
  font-size: 16px !important;
  color: #296c8e !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}
.product-list-tile-cle{
  font-size: 16px !important;
  color: #883a01 !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}

.product-list-tile-deera{
  font-size: 16px !important;
  color: #000 !important;
  font-weight: 700 !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
}
@media screen  and (max-width: 768px){
  .product-list-tilevimpro{
    font-family: FontAwesome;
    font-size: 17px!important;
    color: #2554C7!important;
    font-weight: 600!important;
    overflow: hidden!important;
    display: -webkit-box!important;
    -webkit-line-clamp: 2!important;
    -webkit-box-orient: vertical!important;
    text-overflow: ellipsis!important;
    //min-width: max-content;
  }
  .product-list-tilekub{
    font-family: FontAwesome;
    font-size: 30px!important;
    color: #2554C7!important;
    font-weight: 200!important;
    overflow: hidden!important;
    display: -webkit-box!important;
    -webkit-line-clamp: 2!important;
    -webkit-box-orient: vertical!important;
    text-overflow: ellipsis!important;
    height: 38px;
    //min-width: max-content;
  }
  .product-list-tile1{
    //color: #0497a6;
    font-size: 13px !important;
    //color: #000 !important;
    font-weight: 700 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    height: 38px;
    text-transform: uppercase;
    // background-image: linear-gradient(
    //   -225deg,
    //   #231557 0%,
    //   #44107a 29%,
    //   #ff1361 67%,
    //   #fff800 100%
    // );
    // background-size: auto auto;
    // background-clip: border-box;
    // background-size: 200% auto;
    // color: #fff;
    // background-clip: text;
    // text-fill-color: transparent;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // animation: textclip 2s linear infinite;
    display: inline-block;
        font-size: 190px;
    //text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px grey;
  }
  
}
@media (min-width: 780px) and (max-width: 1920px) {

  .product-list-descriptionkub{
    min-width: max-content;
    padding: 10px 15px !important;
    margin-bottom: 10px !important;
   // margin-top: -35px;
  }
}
@media (max-width: 1920px) {
  .product-list-tile2{
    font-size: 16px !important;
    color: #000 !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
  }

  .product-list-price2{
    font-size: 20px !important;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }

  .rating1{
    font-size: 15px !important;
  }

}


@media (min-width: 360px) and (max-width: 600px) {
  .product-list-descriptionkub{
    padding: 10px 15px !important;
    margin-bottom: 10px !important;
   // margin-top: -35px;
  }
  .product-list-tile2{
    font-size: 12px !important;
    color: #000 !important;
    font-weight: 600 !important;
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
  }

  .product-list-price2{
    font-size: 12px !important;
    color: #2554C7 !important;
    font-weight: 600 !important;
  }

  .rating1{
    font-size: 12px !important;
  }
}

.product-list-description1{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
}
.product-list-description-deera{
  padding: 10px 15px;
  margin-bottom: 10px;
}

.product-list-descriptionkub{
  padding: 10px 15px !important;
  margin-bottom: 10px !important;
  margin-top: -35px;
}
.product-list-image1{
  min-height: 270px !important;
  max-height: 270px !important;
  width: 100% !important;
  // width: 60% !important;
  border-radius: 10px !important;
  object-fit: contain;
  
}
.product-list-image-deera{
  min-height: 270px ;
  max-height: 270px ;
  width: 100% ;
  // width: 60% !important;
  border-radius: 10px ;
  object-fit: contain;
  
}
.product-list-image-avonseals{
  min-height: 275px ;
  max-height: 275px ;
  width: 100% !important ;
  // width: 60% !important;
  border-radius: 10px ;
  object-fit: contain;
  
}
.other-cart{
  width:50%
}
.product-list-image-specialwires1{
    min-height: 190px;
    max-height: 270px;
    width: 81%!important;
    border-radius: 10px;
    object-fit: contain;
}
.product-list-imagekub{

  max-height: 240px!important;
  width:80%!important;
  border-radius: 10px!important;
  object-fit: contain;
}
.listing{
 border-radius: 6px !important;
 padding: 5px 20px !important;
        
  border: none !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.collection-filter {

  background-color: #fff;
  .filter-top {
    border-bottom: 1px solid #dddddd;
    .filter-icon{
      display: none;
    }
    .filter-clear {
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
  .layerd-navigation {
    padding: 10px;
    // font-family: "Inter-Regular";
    border-bottom: 1px solid #dddddd;
    .collection-collapse-block {
      margin-bottom: 0px;
      .collection-collapse-block-content {
        padding-left: 30px;
        .options {
            margin: 8px 0px 8px 0px;
            input {
                margin-right: 10px;
            }
            label{
                margin-bottom: 0px;
                font-size: 15px;
                .count {
                    color: #afabab;
                    font-size: 14px;
                }
            }
        }
      }
    }
  }
}
@media screen and (max-width:768px) {
  .filter-top{
    float: right;
    position: absolute;
    left: 75%;
    margin-top: -10px;
    top: 0%;
    border: none !important;
    .filter-icon{
      display: inline !important;
    }
  }
  .thumb {
    grid-template-columns: repeat(1, 1fr);
    width:111%;
    margin-left:-25px;
  }
  .product-description {
    width: 100% !important;
    display: grid;
    white-space: break-spaces;
  }
  
}
@media (max-width: 1920px) {
  .cartButton{
    font-size: small;
    font-weight: bold;
    width: 45%;
    margin-left: 25%;
    margin-top: 4px;
    background-color: transparent !important;
    border: 3px solid #3c707c !important;
    color: #091214 !important;
  }
  .cartButton1{
    font-size: small;
    font-weight: bold;
    width:152%;
    margin-left:99%;
    margin-top:4px;
    text-align: center;
  }
  .cartButton2{
    font-size: small;
    font-weight: bold;
    width: 45%;
    margin-left: 25%;
    margin-top: 4px;
    background-color: transparent !important;
    border: 3px solid #3c2826 !important;
    color: #091214 !important;
  }
  .product-list-image1{
  min-height: 270px !important;
  max-height: 270px !important;
  width: 100% !important;
  border-radius: 10px !important;
  object-fit: contain;
  }
}


@media (min-width: 360px) and (max-width: 600px) {

  .mobile-confirm{
    margin-left: 11px;
    display: flex; justify-content: center;
  }
  .gen-confirmbutton{
    width: 90px;
  }
  .cartButton{
    font-size: small;
    font-weight: bold;
    margin-left: 0%;
    margin-top: 4px;
    background-color: transparent !important;
    border: 3px solid #3c707c !important;
    color: #091214 !important;
  }
  .cartButton1{
    font-size: small;
    font-weight: bold;
    width:152%;
    margin-left:0%;
    margin-top:4px;
    text-align: center;
  }
  .cartButton2{
    font-size: small;
    font-weight: bold;
    margin-left: 0%;
    margin-top: 4px;
    background-color: transparent !important;
    border: 3px solid #3c2826 !important;
    color: #091214 !important;
  }
  .product-list-image1{
  min-height: 170px !important;
  max-height: 170px !important;
  width: 100% !important;
  border-radius: 10px !important;
  object-fit: contain;
  }
  .product-list-image-deera{
    min-height: 150px ;
    max-height: 150px;
    width: 100%;
    height: 150px;
    }
    .product-list-image-avonseals{
      min-height: 119px ;
      max-height: 200px;
      width: 100%;
      height: 100%;
      }
      .product-list-image-specialwires{
        min-height: 133px ;
        max-height: 200px;
        width: 100% !important;
        }
}

@media  screen and (max-width:640px) {
  .vistas-atc{
    font-size: small;
    font-weight: bold;
    width: 80%;
    margin-top: 4px;
    background-color: transparent !important;
    border: 3px solid #022a19 !important;
    color: #091214 !important;
    background-color: grey;
  }
  .vistas-atc1{
    border-radius: 19px;
    font-size: small;
    font-weight: 700;
    width: 80%;
    margin-top: 4px;
    border: 2px solid #4e90b2!important;
    color: #091214!important;
}

  .avon-foot-img{
    padding:8px;
    object-fit: contain;
    height: 140px;
    width: 300px;
    margin-left: 10%;
  }
.avon-atc{
  width: 74%;
  margin-left: 13%;
 
}
.avon-atc1{
  margin-left: 0%;
  width: 94%;
}
.special-atc{
  margin-left: -3%;
  bottom: 46px;
  position: absolute;
}
.special-atc1{
 
  width: 53%;
 
}
}